import React from "react"
import {Row,Col} from 'antd'

const DETAILPASIEN = () => {
    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Users</h2>
                    <p>Summary analisa data aplikasi kali ini</p>
                </Col>
            </Row>
        </>
    )
}

export default DETAILPASIEN