import React, { useEffect, useState, useCallback } from "react"
import { Row, Col, Card, message } from 'antd'
import { useHistory, useLocation } from "react-router-dom"
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneHistory } from 'redux/features/history';
import { COLOR_2 } from 'constants/ChartConstant';


const DETAILHISTORY = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useSelector(state => state.history)

    const getData = useCallback(async (id) => {
        try {
            await dispatch(fetchOneHistory(id)).unwrap()
        } catch (error) {
            message.error(error?.message || 'Failed to fetch data')
        }
    }, [dispatch])

    useEffect(() => {
        getData(location.id)
    }, [])

    const stateChart = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 1, 3, 2, 4, 42, , 2, 32, 4, 4, 3, 34, 34, 2, 4,]
        }],
        options: {
            chart: {
                type: 'line',
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            colors: [COLOR_2],
            xaxis: {
                type: 'numeric'
            },
            yaxis: {
                logBase:10,
                logarithmic:true
            }
        }
    };

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Hasil Pengukuran</h2>
                    <p>Summary analisa data aplikasi kali ini</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>BPM (50 - 150 Normal)</h3>
                        <h1>{history.selected.bpm}</h1>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>Id Pasien</h3>
                        <h1>{history.selected.user}</h1>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>SCG</h3>
                        <Chart
                            options={stateChart.options}
                            series={[{
                                name: "Sinyal",
                                data: history.selected?.scg
                            }]}
                            height={300}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>References</h3>
                        <img style={{width:"100%",height:"315px"}} src="/img/signal-references.png"></img>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card>
                        <h3>SCG Zoom</h3>
                        <Chart
                            options={stateChart.options}
                            series={[{
                                name: "Sinyal",
                                data: history.selected?.scg
                            }]}
                            height={300}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>PCG</h3>
                        <Chart
                            options={stateChart.options}
                            series={[{
                                name: "Sinyal",
                                data: history.selected?.pcg
                            }]}
                            height={300}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h3>ECG</h3>
                        <Chart
                            options={stateChart.options}
                            series={[{
                                name: "Sinyal",
                                data: history.selected?.pcg
                            }]}
                            height={300}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DETAILHISTORY