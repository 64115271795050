import { Button, Card, Col, Row, Table, message, Tag } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllUser } from 'redux/features/user';

export const TICKET = (properties) => {
	const history = useHistory()
	const dispatch = useDispatch();
	const [title, setTitle] = useState(true)
	const {
		list,
		selectedRows,
		filter: { q: searchTerm },
		loading: {
			query: loadingQuery,
			mutation: loadingMutation
		}
	} = useSelector(state => state.user)

	const getData = useCallback(async () => {
		try {
			await dispatch(fetchAllUser()).unwrap()
		} catch (error) {
			message.error(error?.message || 'Failed to fetch data')
		}
	}, [dispatch])

	useEffect(() => {
		if (properties.noTitle) {
			setTitle(false)
		}
		getData()
	}, [])

	const tableColumns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: '_id',
			responsive: ['md'],
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			responsive: ['md'],
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			responsive: ['md'],
		},
		{
			title: 'Role',
			dataIndex: 'roles',
			key: 'roles',
		},
		{
			title: () => <div className="text-center">Detail</div>,
			key: 'status',
			render: (_, record) => (
				<div className="text-center">
					<a type="primary" style={{ width: "70%" }} onClick={() => {
						history.push({
							pathname: '/app/detail-patient',
							id: record._id,
						})
					}} >Detail</a>
				</div>
			),
		},
	];

	return (
		<>
			{title && (
				<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<h2>Daftar Ticket</h2>
					<p>Summary analisa data aplikasi kali ini</p>
				</Col>
			</Row>
			)}
			<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card title="Daftar Ticket Pertanyaan" >
						<Table
							className="no-border-last"
							columns={tableColumns}
							dataSource={list}
							rowKey='id'
							pagination={false}
						/>
					</Card>
				</Col>
			</Row>
		</>
	)
}


export default TICKET;
