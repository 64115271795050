import React, {  Suspense } from "react";
import { Switch,Redirect} from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import DASHBOARD from "./dashboard"
import HISTORY from "./all-history"
import PATIENTS from "./all-patients"
import TRANSACTIONS from "./all-transactions"
import TICKETS from "./all-tickets"
import SETTINGS from "./settings"

import DETAILHISTORY from "./detail-history"
import DETAILPATIENT from "./detail-patient"
import DETAILTRANSACTION from "./detail-transaction"
import DETAILTICKET from "./detail-ticket"

export const AppViews = ({match}) => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/* <PrivateRoute path={`${match.url}`} component={DASHBOARD} /> */}
        <PrivateRoute path={`${strings.navigation.path.dashboard}`} component={DASHBOARD} />
        <PrivateRoute path={`${strings.navigation.path.history}`} component={HISTORY} />
        <PrivateRoute path={`${strings.navigation.path.tickets}`} component={TICKETS} />
        <PrivateRoute path={`${strings.navigation.path.transactions}`} component={TRANSACTIONS} />
        <PrivateRoute path={`${strings.navigation.path.patients}`} component={PATIENTS} />
        <PrivateRoute path={`${strings.navigation.path.settings}`} component={SETTINGS} />

        {/* DETAIL */}
        <PrivateRoute path={`${strings.navigation.path.detail_history}`} component={DETAILHISTORY} />
        <PrivateRoute path={`${strings.navigation.path.detail_ticket}`} component={DETAILTICKET} />
        <PrivateRoute path={`${strings.navigation.path.detail_transaction}`} component={DETAILTRANSACTION} />
        <PrivateRoute path={`${strings.navigation.path.detail_patient}`} component={DETAILPATIENT} />

        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
