import { Button, Card, Col, Row, Table, message } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { fetchAllHistory } from 'redux/features/history';

export const PASIEN = () => {
	const history = useHistory()
	const dispatch = useDispatch();
	const {
		list,
		selectedRows,
		filter: { q: searchTerm },
		loading: {
			query: loadingQuery,
			mutation: loadingMutation
		}
	} = useSelector(state => state.history)

	const getData = useCallback(async () => {
		try {
			await dispatch(fetchAllHistory()).unwrap()
			console.log(list)
		} catch (error) {
			message.error(error?.message || 'Failed to fetch data')
		}
	}, [dispatch])

	useEffect(() => {
		getData()
	}, [])

	const tableColumns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: '_id',
		},
		{
			title: 'Tanggal',
			dataIndex: 'tanggal',
			key: 'tanggal',
		},
		{
			title: 'Nama Pasien',
			dataIndex: 'user',
			render: (_, record) => {
				return (
					<>{record.user?.namaLengkap}</>
				)
			},
		},
		{
			title: 'BPM',
			dataIndex: 'bpm',
			key: 'bpm',
		},
		{
			title: () => <div className="text-center">Detail</div>,
			key: 'status',
			render: (_, record) => (
				<div className="text-center">
					<a type="primary" style={{ width: "70%" }} onClick={() => {
						history.push({
							pathname: '/app/detail-history',
							id: record._id,
						})
					}} >Hasil</a>
				</div>
			),
		},
	];

	return (
		<>
			<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<h2>Daftar Hasil Pengukuran</h2>
					<p>Summary analisa data aplikasi kali ini</p>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card title="Daftar Hasil Pengukuran" >
						<Table
							className="no-border-last"
							columns={tableColumns}
							dataSource={list}
							rowKey='id'
							pagination={false}
						/>
					</Card>
				</Col>
			</Row>
		</>
	)
}


export default withRouter(PASIEN);
