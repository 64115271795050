import { strings } from 'res';
import { CashHandoverIcon, CommandCenterIcon, CustomerIcon, DashboardIcon, SettingIcon, SLAIcon } from "../assets/svg/icon";

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Tickets",
      path: strings.navigation.path.tickets,
      title: "Tickets",
      icon: CommandCenterIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "History",
      path: strings.navigation.path.history,
      title: "History",
      icon: SLAIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Transaksi",
      path: strings.navigation.path.transactions,
      title: "Transaksi",
      icon: CashHandoverIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pasien",
      path: strings.navigation.path.patients,
      title: "Pasien",
      icon: CustomerIcon,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const ControlTree = [{
  key: "Pengaturan",
  path: strings.navigation.path.settings,
  title: "Pengaturan",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingIcon,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;