import {
	EllipsisOutlined, FileExcelOutlined,
	PrinterOutlined, ReloadOutlined
  } from '@ant-design/icons';
  import React, { useState } from "react";
  import { Card, Col, Dropdown, Menu, Row, Table, Tag } from 'antd';
  import { withRouter } from 'react-router-dom';
  
  export const AnnualStatisticData = [
	{
		title: 'Total Pasien',
		value:'34', 
		status: -11.4,
		subtitle: `Compare to last year (2019)`
	},
	{
		title: 'Total Pengukuran',
		value:'56', 
		status: 8.2,
		subtitle: `Compare to last year (2019)`
	},
	{
		title: 'Total Ticket',
		value:'1', 
		status: 0.7,
		subtitle: `Compare to last year (2019)`
	}
  ]
  
  export const RecentTransactionData = [
	{
		ticketID: '1',
		name: 'Clayton Bates',
		tanggal: '8 May 2022',
		status: 'Approved',
		paket: '1 Tahun',
		price: 'Rp.150.000'
	},
  ];
  
  const latestTransactionOption = (
	<Menu>
	  <Menu.Item key="0">
		<span>
		  <div className="d-flex align-items-center">
			<ReloadOutlined />
			<span className="ml-2">Refresh</span>
		  </div>
		</span>
	  </Menu.Item>
	  <Menu.Item key="1">
		<span>
		  <div className="d-flex align-items-center">
			<PrinterOutlined />
			<span className="ml-2">Print</span>
		  </div>
		</span>
	  </Menu.Item>
	  <Menu.Item key="12">
		<span>
		  <div className="d-flex align-items-center">
			<FileExcelOutlined />
			<span className="ml-2">Export</span>
		  </div>
		</span>
	  </Menu.Item>
	</Menu>
  );
  
  const cardDropdown = (menu) => (
	<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
	  <a href="/#" className="text-gray font-size-lg" onClick={e => e.preventDefault()}>
		<EllipsisOutlined />
	  </a>
	</Dropdown>
  )
  
  const tableColumns = [
	{
	  title: 'ID',
	  dataIndex: 'ticketID',
	  key: 'ticketID',
	},
	{
	  title: 'Tanggal',
	  dataIndex: 'tanggal',
	  key: 'tanggal',
	},
	{
	  title: 'Pasien',
	  dataIndex: 'name',
	  key: 'name',
	},
	{
		title: 'Paket',
		dataIndex: 'paket',
		key: 'paket',
	  },
	{
	  title: 'Price',
	  dataIndex: 'price',
	  key: 'price',
	},
	{
	  title: () => <div className="text-center">Status</div>,
	  key: 'status',
	  render: (_, record) => (
		<div className="text-center">
		  <Tag className="mr-0" color={record.status === 'Approved' ? 'cyan' : record.status === 'Pending' ? 'blue' : 'volcano'}>{record.status}</Tag>
		</div>
	  ),
	},
  ];
  
  export const PASIEN = () => {
	const [recentTransactionData] = useState(RecentTransactionData)
  
	return (
	  <>
		<Row gutter={24}>
		  <Col xs={24} sm={24} md={24} lg={24}>
			<h2>Daftar Transaksi</h2>
			<p>Summary analisa data aplikasi kali ini</p>
		  </Col>
		</Row>
		<Row gutter={24}>
		  <Col xs={24} sm={24} md={24} lg={24}>
			<Card title="Daftar Transaksi" >
			  <Table
				className="no-border-last"
				columns={tableColumns}
				dataSource={recentTransactionData}
				rowKey='id'
				pagination={false}
			  />
			</Card>
		  </Col>
		</Row>
	  </>
	)
  }
  
  
  export default withRouter(PASIEN);
  