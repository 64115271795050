import { Col, Row } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import TICKET from '../all-tickets';

export const AnnualStatisticData = [
  {
      title: 'Total Pasien',
      value:'2', 
  },
  {
      title: 'Total Pengukuran',
      value:'1', 
  },
  {
      title: 'Total Ticket',
      value:'2', 
  }
]

export const DefaultDashboard = () => {
  const [annualStatisticData] = useState(AnnualStatisticData);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Summary analisa data aplikasi kali ini</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16}>
            {
              annualStatisticData.map((elm, i) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
                  <StatisticWidget
                    style={{textAlign:"center"}}
                    title={elm.title}
                    value={elm.value}
                    status={elm.status}
                    subtitle={elm.subtitle}
                  />
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
      <TICKET noTitle={true} ></TICKET>
    </>
  )
}


export default withRouter(DefaultDashboard);
