import React from "react"
import { Row, Col, Card } from 'antd'

const DETAILTRAKSAKSI = () => {
    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>Detail Pasien</h2>
                    <p>Summary analisa data aplikasi kali ini</p>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h1>SCG</h1>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <h1>PCG</h1>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DETAILTRAKSAKSI