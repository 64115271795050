const URLS = Object.freeze({
	LOGIN: `/auth/login`,
	REGISTER: `/auth/register`,
	STORE_GOOGLE_ACCOUNT: `/user/auth-google`,
	ACTIVATION: `/send-activation`,
	RESET_PASSWORD: `user/reset-password`,
	RESEND_ACTIVATION: `user/resend-activation`,
	PROFILE: `/users`,
	HISTORY: `/history`,
	USER:'/users',
	TICKET: `/ticket`,
})

export default URLS