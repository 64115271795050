import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/features/theme'
import authReducer from 'redux/features/auth'
import historyReducer from 'redux/features/history'
import ticketReducer from 'redux/features/ticket'
import userReducer from 'redux/features/user'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    history:historyReducer,
    user:userReducer,
    ticket:ticketReducer
  }
});

export default store;

